require.config({
	baseUrl: '/Files/Templates/Designs/LM2016/assets/vendor/',//designBaseUrl+'assets/vendor/',
	paths: {
		'jquery': 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min',//'jquery-1.11.1.min', //'empty:',
		'jquery-zoom' : 'jquery.zoom.min',
		'bootstrap' : 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min', //bootstrap.min',
		'owl-carousel': 'owl.carousel2.min',
		'mmenu': 'mmenu/umd/jquery.mmenu.umd.all.min',
		'formvalidation': 'formValidation.min',
		'bootstrapValidation': 'framework/bootstrap.min',
	},
	shim: {
		'bootstrap': ['jquery'],
		'owl-carousel': ['jquery'],
		'mmenu': ['jquery'],
		'bootstrapValidation':['formvalidation'],
		'formvalidation': ['jquery'],
	}
});
require(
	['jquery', 'bootstrap', 'owl-carousel', 'mmenu', 'formvalidation', 'bootstrapValidation'],

	function ($) {
    if (!window.matchMedia && !window.msMatchMedia) {
      require(['respond']);
    }

	$(document).ready(function () {
		$('.carousel').carousel();

		//removes content from modal box
		$('body').on('hidden', '.modal', function () {
			$(this).removeData('modal');
		});
		$('body').on('click.collapse-next.data-api', '[data-toggle=collapse-next]', function (e) {
				var $this = $(this)
					, $target = $(this).parents('.collapse-container').find('.collapse');
				$target.collapse('toggle');
				$this[!$target.hasClass('in') ? 'addClass' : 'removeClass']('collapsed');
		});

		$(function () {
			var $affixElement = $('div[data-spy="affix"]');
			$affixElement.width($affixElement.parent().width());
		});

		$('#Modal').on('show.bs.modal', function (event) {
			var img = $(event.relatedTarget).find('img'); //Get clicked image object 
			var title = img.attr('alt');
			var bodyContent = '<img src="' + img.data('full-image') + '" alt="' + img.attr('alt') + '" class="img-responsive" />'; //construct new img tag from data-full-image
			var modal = $(this)
			modal.find('.modal-title').text(title)
			modal.find('.modal-body').html(bodyContent)
		})

		$('#phoneMenu').mmenu({
			"offCanvas": {
				"position": "top",
				"pageNodetype": "header",
				"zposition": "front"
			},
			"counters": true,
			"footer": {
				"add": true,
				"title": "Tap below to exit menu"
			},
			"header": {
				"title": "Main menu",
				"add": true,
				"update": true
			},
			"classes": "mm-white",
			"labels": true,
			"setSelected": true
		});
			
	});
});
